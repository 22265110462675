"use client";

import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.scss";
import {  useEffect, useState } from "react";
import slideCalculator from "@/helpers/testimonialSlideCalculator";
import { IModalActions, ModalTypes } from "@/types/modal";
import { ModalButton } from "../atoms/ModalButton";

export default function StudentsCarousel({
  studensDetails,
}: {
  studensDetails: Testimonial[];
}) {
  return (
    <div className="Carousel">
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={16}
        slidesPerView={1.1}
        autoplay={{
          delay: 6000,
        }}
        pagination={{ clickable: true, bulletElement: "div" }}
        className="Carousel__blueBullet Carousel__withPreview Carousel__hAuto"
        breakpoints={{
          1024: {
            spaceBetween: 26,
          },
        }}
      >
        {studensDetails?.map((item, i) =>
          item.attributes.enabled ? (
            <SwiperSlide key={i}>
              <TestimonialComponent items={item} />
            </SwiperSlide>
          ) : null,
        )}
      </Swiper>
    </div>
  );
}

function TestimonialComponent({ items }: { items: Testimonial }) {
  return (
    <a href={items?.attributes?.url_key || "/"} className="h-full">
      <div className="border border-primary-blue rounded-2xl text-primary-blue pb-[21px] lg:pb-0 flex flex-col h-full overflow-hidden">
        <div className="mx-auto overflow-hidden lg:p-2 pb-0 pl-0 xl:pl-0 lg:w-[100%] lg:border-r-0 lg:border-l lg:shrink-0 lg:basis-2/5 xl:mx-0 StudentTestimonial_img">
          <img
            src={items?.attributes?.Picture.source.data[0].attributes.url}
            alt={
              items?.attributes?.Picture.source.data[0].attributes
                .alternativeText || ""
            }
            className="object-cover w-full h-full"
            loading="lazy"
          />
        </div>
        <div className="px-[21px] mt-4 flex flex-col justify-between lg:py-[33px] lg:pl-[26px] lg:pr-[49px] flex-1">
          <p className="lg:text-xl font-medium font-raleway lg:leading-7 lg:tracking-[0.4px] xl:max-w-[608px]">
            {items?.attributes?.ShortDesc}
          </p>
          <div className="mt-12">
            <h5 className="font-baskervville text-3xl font-normal lg:text-[34.90px] lg:leading-[43.62px] lg:tracking-[0.4px]">
              {items?.attributes?.Name}
            </h5>
            <p className="mt-[7.56px] text-sm font-raleway font-medium lg:text-lg lg:leading-[20.92px] lg:tracking-[1.05px] xl:max-w-[263px] opacity-80">
              {items?.attributes?.Department} {items?.attributes?.Designation}
            </p>
          </div>
        </div>
      </div>
    </a>
  );
}

function VideoTestimonialComponent({ items }: { items: VideoTestimonials }) {
  
  return (
    <div className="border border-primary-blue rounded-2xl bg-blue-linear-gradient h-full w-full xl:overflow-hidden">
      <figure className={"relative h-full"}>
        <img
          src={items?.attributes?.VideoEmbed.PosterImage?.data?.attributes?.url}
          alt={
            items?.attributes?.VideoEmbed.PosterImage?.data?.attributes
              ?.alternativeText || "Video Testimonial Component"
          }
          className="object-cover w-full h-[40vh] xl:h-full rounded-2xl mix-blend-multiply videoContainer"
          loading="lazy"
        />
        <figcaption className="absolute bottom-0 left-0 w-full">
          <div className="text-white flex items-end justify-between gap-[17px] pt-[13.53px] pb-[15.57px] pl-5 pr-[18px]">
            <div>
              <h2 className="font-baskervville text-[24px] leading-[26.7px] font-normal lg:text-[35px] lg:leading-[44px] lg:-tracking-[0.7px] [text-shadow: 0px 4.362116813659668px 4.362116813659668px rgba(0, 0, 0, 0.25)]">
                {items?.attributes?.Name}
              </h2>
              <p className="mt-[7.56px] text-sm font-raleway font-medium xl:max-w-[263px] lg:text-[18px] lg:leading-[21px] lg:tracking-[1.04px]">
                {items?.attributes?.Department} {items?.attributes?.Designation}
              </p>
            </div>
            <ModalButton
              action={IModalActions.OpenModal}
              data={{
                modalType: ModalTypes.VideoPopup,
                modalData: {
                  url: items?.attributes?.VideoEmbed?.enabled
                    ? items?.attributes?.VideoEmbed?.URL
                    : "",
                },
              }}
            >
              {items?.attributes?.ActionIcon?.map(
                (
                  { alt, isMobileViewIcon, source, target, title, url },
                  i: number
                ) =>
                  isMobileViewIcon ? (
                    <span
                      className={`h-9 w-9 max-w-none inline-block lg:hidden cursor-pointer homeSprite studentPlayIcon`}
                      key={i}
                    ></span>
                  ) : (
                    <img
                      src={source?.data?.attributes?.url}
                      alt={alt || 'View'}
                      key={i}
                      className="h-[61px] w-[60px] hidden lg:inline-block cursor-pointer"
                      loading="lazy"
                    />
                  )
              )}
            </ModalButton>
          </div>
        </figcaption>
      </figure>
    </div>
  )
}

export function StudentsVideoCarousel({
  studentsDetails,
}: {
  studentsDetails: VideoTestimonials[];
}) {
  return (
    <div className="Carousel mt-[37.6px]">
      <Swiper
        autoplay={{
          delay: 6000,
        }}
        modules={[Pagination, Autoplay]}
        spaceBetween={16}
        slidesPerView={1.1}
        pagination={{ clickable: true, bulletElement: "div" }}
        className="Carousel__blueBullet Carousel__videoPreview"
      >
        {studentsDetails?.map(
          (item, i) =>
            item.attributes.enabled && (
              <SwiperSlide key={i}>
                <VideoTestimonialComponent items={item} />
              </SwiperSlide>
            )
        )}
      </Swiper>
    </div>
  )
}

export function StudentsGridCarousel({
  studentsDetails,
  videoTestimonials,
}: {
  studentsDetails: Testimonial[];
  videoTestimonials: VideoTestimonials[];
}) {
  const [testimonials, setTestimonials] = useState<any>([]);

  useEffect(() => {
    const allTestimonials = slideCalculator(studentsDetails, videoTestimonials);
    setTestimonials(allTestimonials.arrayToRender);
  }, []);

  return (
    <Swiper
      modules={[Pagination]}
      spaceBetween={16}
      slidesPerView={1.05}
      pagination={{ clickable: true, bulletElement: "div" }}
      className="Carousel__blueBullet"
      autoplay={{
        delay: 6000,
      }}
    >
      {testimonials.map((item: any, i: number) => (
        <SwiperSlide key={i}>
          <div key={i} className="flex flex-col">
            <div className="flex">
              <div className="basis-[65%]">
                <TestimonialComponent items={item[0]} />
              </div>
              <div className="basis-[35%]">
                <VideoTestimonialComponent items={item[2]} />
              </div>
            </div>
            <div className="flex">
              <div className="basis-[35%]">
                <VideoTestimonialComponent items={item[3]} />
              </div>
              <div className="basis-[65%]">
                <TestimonialComponent items={item[1]} />
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
